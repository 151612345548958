.clock-container {
  position: relative;
  width: 100%; /* Adjust based on your image size */
  height: 100%; /* Adjust based on your image size */
  margin: 0 auto;
}

.clock {
  position: relative;
  width: 100%;
  height: 100%;
}

.clock-background,
.pack-title {
  position: absolute;
  border-radius: 50%;
  left: 99px;
  top: 104px;
  width: 192px;
  height: 192px;
  object-fit: cover;
}

.pack-title {
  position: relative;
  top: 292px;
  left: 0px;
  text-align: center;
  z-index: 1;
  color: aliceblue;
  word-wrap: break-word;
  font-weight: bold;
  max-width: 154px;
  margin: 10px auto;
  overflow-wrap: break-word;
}

.wall-clock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pendulum {
  position: absolute;
  border-radius: 50%;
  bottom: 78px; /* Adjust based on your image size */
  left: 195px;
  width: 153px; /* Adjust based on your image size */
  height: 153px; /* Adjust based on your image size */
  transform: translateX(-50%);
  background-size: cover;
  background-repeat: no-repeat;
}
