::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.tooltiptext {
  visibility: hidden;
  width: 260px;
  background-color: black;
  color: #fff;
  position: absolute;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  z-index: 1;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.circle:hover .tooltiptext {
  visibility: visible;
}

body {
  color: black;
}

.card-body {
  color: black;
}

.dot {
  height: 25px;
  width: 100px;
  display: inline-block;
  margin-left: 20px;
  padding-left: 20px;
  border-radius: 10px;
}

p {
  margin: 0;
}

@keyframes pendulum-swing {
  0% {
    transform: rotate(4deg);
  }

  100% {
    transform: rotate(-4deg);
  }
}

.form-control::placeholder {
  color: #808080;
}

.custom-modal-style {
  height: 100%;
}

.custom-dialog-style {
  height: 80%;
}

.disabled {
  color: rgb(119, 119, 110);
  opacity: 0.5;
}

#fuse-splash-screen-version2 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  pointer-events: none;
}

#fuse-splash-screen-version2 .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#fuse-splash-screen-version2 .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner .left,
#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

#fuse-splash-screen-version2
  .spinner-wrapper
  .spinner
  .inner
  .left
  .half-circle,
#fuse-splash-screen-version2
  .spinner-wrapper
  .spinner
  .inner
  .right
  .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border: 10px solid #0f0f22;
  border-bottom-color: transparent;
  border-radius: 50%;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner .left {
  left: 0;
}

#fuse-splash-screen-version2
  .spinner-wrapper
  .spinner
  .inner
  .left
  .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s linear(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen-version2 .spinner-wrapper .spinner .inner .right {
  right: 0;
}

#fuse-splash-screen-version2
  .spinner-wrapper
  .spinner
  .inner
  .right
  .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1)
    infinite;
}

.rbc-event {
  background-color: unset;
  color: #0f0f22;
  border: 0 !important;
  cursor: unset;
}

.rbc-event.rbc-selected {
  background-color: unset;
  width: 90 !important;
}

.rbc-event-content {
  border: 0 !important;
}
.rbc-date-cell {
  display: flex;
  flex: 1 1;
  flex-flow: column;
  padding-right: 2px;
  padding-top: 2px;
}

.rbc-date-cell.rbc-now {
  font-weight: unset;
  background-color: white;
}

.rbc-month-header {
  flex: unset;
}

.rbc-month-row {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}

.rbc-row-content {
  display: flex;
  flex: 1;
}
.rbc-row {
  flex: 1;
}

.suggestion-list {
  overflow-y: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 190px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
}

.suggestion-list::-webkit-scrollbar {
  display: none;
}

.react-datepicker-wrapper input[type="text"] {
  border-radius: 0.375rem;
  border: 1px solid #969696;
}

.form-control::-webkit-input-placeholder {
  color: #565656 !important;
}

.scrolling-text {
  /* Set initial overflow to hidden */
  overflow-x: hidden;
}

.scrolling-text:hover {
  overflow-x: auto; /* Show the scrollbar on hover */
}

.scrolling-text::-webkit-scrollbar {
  margin: 0px; /* Width of the scrollbar */
}

@keyframes flash {
  50% {
    color: gray;
  }
}

.flash {
  animation: flash 1s infinite;
}
