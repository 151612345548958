body>.login {
  background-color: #FFF2EDE1;
  height: calc(100vh);
}

body>.login .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

body>.login .logo {
  width: 256px;
  margin: 15px;
}